import { configureStore } from "@reduxjs/toolkit";

import gamesSlice from "./games/gamesSlice";
import loaderSlice from "./loader/loaderSlice";
import myselfSlice from "./myself/myselfSlice";

export const store = configureStore({
  reducer: {
    gamesRedux: gamesSlice,
    myselfRedux: myselfSlice,
    loaderRedux: loaderSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
