import { getMe } from "appdata/myself/myselfSlice";
import { AppDispatch } from "appdata/store";
import MasterLayout from "layouts/MasterLayout";
import Dashboard from "pages/dashboard/Dashboard";
import GamePlay from "pages/game/GamePlay";
import Login from "pages/login/Login";
import { ReactNode, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";

// Hàm kiểm tra nếu user đã đăng nhập
const isAuthenticated = (): boolean => {
  return !!sessionStorage.getItem("mi01_auth"); // Trả về true nếu có mi01_auth
};

// Kiểu cho props của ProtectedRoute
interface ProtectedRouteProps {
  children: ReactNode;
}

// ProtectedRoute component để kiểm tra quyền truy cập
const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  if (!isAuthenticated()) {
    // Chuyển hướng đến login nếu không có playFabUser
    return <Navigate to="/login" />;
  }

  // Hiển thị nội dung nếu đã đăng nhập
  return <>{children}</>;
};

function AppRoutes() {
  const dispatch = useDispatch<AppDispatch>();
  const { i18n } = useTranslation();

  if (!window._env_) {
    window._env_ = {};
  }

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem("mi01_la") ?? "ja");
    if (isAuthenticated()) {
      dispatch(getMe());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Routes>
      <Route path="/" element={<MasterLayout />}>
        <Route path="/login" element={<Login />}></Route>
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route path="/gameplay" element={<GamePlay />} />
        <Route path="/" element={<Navigate to="/dashboard" />} />
        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Route>
    </Routes>
  );
}

export default AppRoutes;
