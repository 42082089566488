import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  BaseApiParams,
  PageResponseData,
  PaginationMetadata,
  ResponseData,
} from "types/apiTypes";
import { Game, GameConfig } from "types/gameTypes";
import axiosInstance from "utils/api";
import { generateQueryParam } from "utils/urlEncode";

interface GamesState {
  games: Game[] | null;
  metadataGamesPage: PaginationMetadata | null;
  gameConfigsOfUser: Record<string, any> | null;
}

const initialState: GamesState = {
  games: null,
  metadataGamesPage: null,
  gameConfigsOfUser: null,
};

export const gamesSlice = createSlice({
  name: "games",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getGames.pending, (state, action) => {})
      .addCase(
        getGames.fulfilled,
        (state, action: PayloadAction<PageResponseData<Game>>) => {
          state.games = action.payload.data;
          state.metadataGamesPage = action.payload.meta;
        }
      )
      .addCase(getGames.rejected, (state, action) => {})
      .addCase(getGameConfigsOfUser.pending, (state, action) => {})
      .addCase(
        getGameConfigsOfUser.fulfilled,
        (state, action: PayloadAction<ResponseData<Record<string, any>>>) => {
          state.gameConfigsOfUser = action.payload.data;
        }
      )
      .addCase(getGameConfigsOfUser.rejected, (state, action) => {});
  },
});

export interface GetGamesParams extends BaseApiParams {
  name?: string;
  type?: string[];
}

export const getGames = createAsyncThunk<
  PageResponseData<Game>,
  GetGamesParams | undefined
>("games/getGames", async (params, { rejectWithValue }) => {
  try {
    const queryStringPart = generateQueryParam(params || {});
    const response = await axiosInstance.get(`/games${queryStringPart}`);
    return response.data;
  } catch (error) {
    return rejectWithValue(
      error instanceof Error ? error.message : "Unknown error"
    );
  }
});

export const getGameConfigsOfUser = createAsyncThunk<
  ResponseData<Record<string, any>>,
  void
>("games/getGameConfigsOfUser", async () => {
  try {
    const respone = await axiosInstance.get(`/game-configs`);
    return respone.data;
  } catch (error) {
    throw new Error(error instanceof Error ? error.message : "Unknown error");
  }
});

export interface UpdateGameConfigParams {
  gameId: string;
  config: Record<string, GameConfig>;
}

export const updateGameConfigOfUser = createAsyncThunk<
  ResponseData<Record<string, any>>,
  UpdateGameConfigParams
>(
  "games/updateGameConfigOfUser",
  async ({ gameId, config }: UpdateGameConfigParams) => {
    try {
      const respone = await axiosInstance.patch(
        `/game-configs/${gameId}`,
        config
      );
      return respone.data;
    } catch (error) {
      throw new Error(error instanceof Error ? error.message : "Unknown error");
    }
  }
);

export default gamesSlice.reducer;
