export const UNITS = {
  SECONDS: "seconds",
  COUNT: "count",
  NONE: "none",
} as const;

export type UnitType = (typeof UNITS)[keyof typeof UNITS];

export const UNIT_CONFIG: Record<string, UnitType> = {
  timePerTurn: UNITS.SECONDS,
  countOfTurn: UNITS.COUNT,
  maxNumber: UNITS.NONE,
  duration: UNITS.SECONDS,
  goalHeadUp: UNITS.COUNT,
  goalHeadDown: UNITS.COUNT,
  durationSecond: UNITS.SECONDS,
  calculationDifficulty: UNITS.NONE,
  goalBend: UNITS.COUNT,
  numberOfFlip: UNITS.NONE,
};
